import React, { Component } from 'react';
import './css/PagerCtrl.css';

class PagerCtrl extends Component {
    constructor(props) {
      super(props);

      this.limitLowerBound = this.limitLowerBound.bind(this);
      this.limitUpperBound = this.limitUpperBound.bind(this);
      this.handleMinusTen = this.handleMinusTen.bind(this);
      this.handleMinusOne = this.handleMinusOne.bind(this);
      this.handlePlusOne = this.handlePlusOne.bind(this);
      this.handlePlusTen = this.handlePlusTen.bind(this);
      this.setCurrentPage = this.setCurrentPage.bind(this);
      this.handleGoFirstPage = this.handleGoFirstPage.bind(this);
      this.handleGoLastPage = this.handleGoLastPage.bind(this);
    }

  limitLowerBound(iPage) {
    let res = Math.max(iPage, 0);
    //console.log("llb:"+res);
    return res;
  }

  limitUpperBound(iPage) {
    let res = Math.min(iPage, this.props.nPages-1);
    //console.log("lub:"+iPage+" "+this.props.nPages+"-> "+res);
    return res;
  }

  setCurrentPage(i) {
    this.props.changePageHandler(i);
  }

  handleMinusTen() {
    let newCurrentPage = this.limitLowerBound(this.props.cp - 10);
    this.setCurrentPage(newCurrentPage);
  }

  handleMinusOne() {
    let newCurrentPage = this.limitLowerBound(this.props.cp - 1);
    this.setCurrentPage(newCurrentPage);
  }

  handlePlusOne() {
    let newCurrentPage = this.limitUpperBound(this.props.cp + 1);
    this.setCurrentPage(newCurrentPage);
  }

  handlePlusTen() {
    let newCurrentPage = this.limitUpperBound(this.props.cp + 10);
    this.setCurrentPage(newCurrentPage);
  }

  handleGoFirstPage() {
    let newCurrentPage = 0;
    this.setCurrentPage(newCurrentPage);
  }

  handleGoLastPage() {
    let newCurrentPage = this.props.nPages-1;
    this.setCurrentPage(newCurrentPage);
  }

 render() {
   const current_page_for_user = this.props.cp + 1;
   let last_page_number = 0;
   if (this.props.nPages !== "undefined") {
     last_page_number = this.props.nPages
   }

   return (
    <div className="pager_ctrl">
    <button onClick={this.handleGoFirstPage}>1</button>
    <button onClick={this.handleMinusTen}>-10</button>
    <button onClick={this.handleMinusOne}>-1</button>
    {current_page_for_user}/{last_page_number}
    <button onClick={this.handlePlusOne}>+1</button>
    <button onClick={this.handlePlusTen}>+10</button>
    <button onClick={this.handleGoLastPage}>{last_page_number}</button>
    </div>
    );
  }
}

export default PagerCtrl;
