import React from 'react';
import './BoutonMultiEtats.css';


/*
Objectif: bouton BoutonMultiEtats: rotation séquentielle d'un état à l'autre
*/
function BoutonMultiEtatsControlled(props) {
  const etat = props.jsonObj.initState;
  const i = props.jsonObj.etats.findIndex( item => item.stateName === etat);
  const img = props.jsonObj.etats[i].icon;
  const stName = props.jsonObj.etats[i].stateName;

  return (
    <label className="switch" onClick={props.onClick}>
    <span className="rectButton"><img src={img} alt={stName} width="10mm" /></span>
    </label>
    );
}

export default BoutonMultiEtatsControlled;
