export function normalize(strIn) {
     var strOut = strIn.toLowerCase();
     // .replace(/ le | la | les | et | de |l'|c'|d'/g,' ')  // intéressant??
     strOut = strOut.replace(/à|â|ä/g, "a");
     strOut = strOut.replace(/é|è|ê|ë/g, "e");
     strOut = strOut.replace(/ï|î/g, "i");
     strOut = strOut.replace(/ô|ö/g, "o");
     strOut = strOut.replace(/œ/g, "op");
     strOut = strOut.replace(/ù|û|ü/g, "u");
     strOut = strOut.replace(/ÿ|ŷ/g, "y");
     //strOut = strOut.replace(/,|–|-| |(|)|'|,|||\.|\?|!/g, " ");
     //!! strOut = strOut.replace(/,|\(|\)|-|–|:|?|!|(\.\.\.)|«|“/g, " ");
     strOut = strOut.replace(/ +/g, " ");
     strOut = strOut.replace(/^( +)/,'');
     return strOut;
   }

export function buildFieldsList(searchArgs) {
     let searchFields=[];
     if (searchArgs.titre===true) {
        searchFields.push('item_name');
     }
     if (searchArgs.paroles===true) {
       searchFields.push('lyrics');
     }
     if (searchArgs.auteur===true) {
       searchFields.push('Auteur');
     }
     if (searchArgs.compositeur===true) {
       searchFields.push('Compositeur');
     }
     let junctionOp = '$and';
     if (searchArgs.union===true) {
       junctionOp = '$or';
     }
     let resObj={
       fields_list:searchFields,
       junctionOp:junctionOp
     }
     return resObj;
   }

export function gen_req(strSearch, searchArgs) {
  let req = {};
  strSearch = normalize(strSearch);
  let litterals = strSearch.split(' ');
  let resObj = buildFieldsList(searchArgs);
  let junctionOp = resObj.junctionOp;
  let fields_list = resObj.fields_list;

  let frp = []; //field request part
  for (let ifield in fields_list) {
    let field = fields_list[ifield];
    let lrp = []; //litteral request part
    for (let ilitt in litterals) {
      let litt = litterals[ilitt];
      let obj = {};
      obj[field] = {'$regex' : litt};
      lrp.push(obj);
    }
    let litteralJuncObj = {};
    litteralJuncObj[junctionOp] = lrp;
    frp.push(litteralJuncObj);
  }
  let fieldsJunctionObj = {'$or':frp};

  req.selector = fieldsJunctionObj;
  req.use_index = [
      "toto",
      "vagreg"
   ];
  req.limit=10000;
  req.execution_stats=true;
  return req;
}
