import React, { Component } from 'react';
import './ViewListeChants.css';
import VignetteChant from '../VignetteChant/VignetteChant.js';
import Pager from '../../components/Pager/Pager.js';


class ViewListeChants extends Component {
  constructor(props) {
    super(props);
    this.itemRenderFunc = this.itemRenderFunc.bind(this);

  }

  itemRenderFunc(item) {
    return (<VignetteChant key={item._id} jsonContent={item} searchStr={this.props.searchStr}/>);
  }

 render() {
   let docs=[];
   let nbDocs=0;
   if (typeof(this.props.jsonContent) !== 'undefined'
     && typeof(this.props.jsonContent.docs) !== 'undefined' ) {
    docs = this.props.jsonContent.docs;
    nbDocs = this.props.jsonContent.numFound;
   }

  return (
    <div className="viewListeChants" >
    <span className="lbl">Nombre chants trouvés: {nbDocs}</span>
    <Pager ref={this.props.passPagerRef} itemList={docs} itemRenderFunc={this.itemRenderFunc} />
    {/* servira pour highlighting
      this.props.searchStr*/}
    {/*(nbDocs<50?chants:{})*/}
    {/*(nbDocs<50?chants:"")*/}

    </div>
    );
  }

}

export default ViewListeChants;
