

//convnience component that integrates a PagerCtrl under a PageView
//this helped me
//https://www.freecodecamp.org/news/react-changing-state-of-child-component-from-parent-8ab547436271/

import React, { Component } from 'react';
import PagerCtrl from './PagerCtrl.js';
import PageView from './PageView.js';

class Pager extends Component {
    constructor(props) {
     super(props);
     const items_per_page = 50;
     let nPages = 0;
     this.state = {
        "current_page": 0 ,
        "items_per_page": items_per_page,
        "nPages":nPages,
        "items":[],
        "prevItems":[]
        };
     this.handleChangePage = this.handleChangePage.bind(this);
   }

   handleDoUpdate = () => {
     this.handleChangePage(0);
   };



   handleChangePage(i) {
       this.setState({"current_page": i});
       const items_per_page = this.state.items_per_page;
       let itStart = items_per_page * i;
       let itEnd = items_per_page * (i+1);
       let nPages = 0;
       let items = [];
       if (typeof(this.props.itemList) !== "undefined") {
         const nbItems = this.props.itemList.length;
         const quotientEntier = Math.round(nbItems / items_per_page);
         const reste = nbItems % items_per_page;
         nPages = quotientEntier
                  + (reste ? 1: 0) // on ajoute 1 si on a un reste (à caser dans page supplémentaire)
                  - (quotientEntier!==0 ? 1: 0); // on enlève 1 pour l'indiçage à partir de 0, sauf si il n'y a qu'une page (on est déjà à 0)
         items = this.props.itemList.slice(itStart, itEnd).map(this.props.itemRenderFunc);
       }
       this.setState({"nPages":nPages});
       this.setState({"prevItems": this.state.items });
       this.setState({"items": items });
   }


 render() {
   const items = this.state.items;

  return (
    <React.Fragment>
    <PageView items={items} />
    <PagerCtrl cp={this.state.current_page} nPages={this.state.nPages} st={this.state} changePageHandler={this.handleChangePage} />
    </React.Fragment>
    );
  }

}

export default Pager;
