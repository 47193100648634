import React, { Component } from 'react';
import './ViewJsonRequest.css';


class ViewJsonRequest extends Component {
  // constructor(props) {
  //   super(props);
  // }

 render() {
   const jsonContent = this.props.jsonContent;
   
  return (

    <div className="viewJsonRequest" >
    Requete:
      <pre>
      {JSON.stringify(jsonContent, null, 2)}
      </pre>
    </div>
    );
  }

}

export default ViewJsonRequest;
