import React, { Component } from 'react';
import './css/PageView.css';

class PageView extends Component {

 render() {
   const items = this.props.items;

  return (
    <div className="items_container">
    {items}
    </div>
    );
  }

}

export default PageView;
