import React, { Component } from 'react';
import './ScreenEditChant.css';
import Form from '../primitives/Form/Form.js';
import TextInput from '../primitives/Form/TextInput.js';
import SubmitInput from '../primitives/Form/SubmitInput.js';

//import conf from '../../config/config.json';
import DbRequests from '../../services/xhr.js';
//const dbserv = new DbRequests(conf.services);
const dbserv = new DbRequests("");

const getId = function() {
  return 'toto';
}

class ScreenEditChant extends Component {
   constructor(props) {
     super(props);
     this.handleChange = this.handleChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
     this.renderJsonFormField = this.renderJsonFormField.bind(this);
     this.handlePutReply = this.handlePutReply.bind(this);
     let formObj = {
       form_name:'edit_chant',
       inputs: [
         {'inputType':'textInput', 'name':'age', 'label':'age','value':'18'},
         {'inputType':'textInput', 'name':'nom', 'label':'nom','value':'' },
         {'inputType':'textInput', 'name':'prenom', 'label':'prenom','value':''},
         {'inputType':'submitInput', 'name':'submit', 'value':'ok'}
       ]
     };
     this.state = {formObj:formObj};
   }

  //can be triggered by different sources
  //we want to collect the value and return it to the upper handler
  handleSubmit() {
    const formObj = this.state.formObj;

    let cfunc = function(fielddata) {
      if (fielddata.inputType === 'textInput') {
        let obj = {};
        obj[fielddata.name] = fielddata.value;
        return obj;
      }
    }

    const reducer = (o, oIn) => Object.assign(o, oIn);

    let data = formObj.inputs
      .map(cfunc)
      .filter(x => x !== undefined)
      .reduce(reducer, {});
    console.log(JSON.stringify(data));
    //collect data to send
    //validation
    //if ok, send data
    dbserv.puput(getId(), data).then(
      reply => this.handlePutReply(reply)
    );

  }

  handlePutReply(reply) {
    console.log(reply);
  }

  handleChange(event) {
    const name = event.target.name;

    let formObj = this.state.formObj;
    formObj.inputs.find(x => x.name === name).value = event.target.value;
    this.setState({
      formObj: formObj
    });
  }

//type = {jsonFeed.inputType}
  renderJsonFormTextInputField(jsonFeed) {

    return(
      <TextInput

        key = {jsonFeed.name}
        jsonFeed = {jsonFeed}
        handleChange = {this.handleChange}
      />
    );
  }

renderJsonFormField(jsonFeed) {

  if (jsonFeed.inputType === 'textInput') {
    return(
      <TextInput

        key = {jsonFeed.name}
        jsonFeed = {jsonFeed}
        handleChange = {this.handleChange}
      />
    );
  }
  if (jsonFeed.inputType === 'submitInput') {
    return(
      <SubmitInput
        type = {jsonFeed.inputType}
        key = {jsonFeed.name}
        jsonFeed = {jsonFeed}
        handleChange = {this.handleChange}
      />
    );
  }

}


 render() {

   const formObj = this.state.formObj;
   const formFields = this.state.formObj.inputs.map(this.renderJsonFormField);

  return (
    <div className="container_box">
    <Form onSubmit={this.handleSubmit}>{/*this.handleSubmit*/}
    {formFields}

    </Form>
    {JSON.stringify(formObj)}

    </div>
    );
  }

}
//// ce qui suit n'est pas fonctionnel.
//pour que ça le soit, il faudrait probablement que chaque form maintienne
//un état interne.
   // <TextInput
   //   jsonFeed='{"name":"profession","label":"profession", "value":"test"}'
   //   handleChange={this.handleChange}
   //    />
export default ScreenEditChant;
