import React, { Component } from 'react';
import './SearchBar.css';
import ico_search from './img/search.png';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  focusTextInput() {
     this.textInput.current.focus();
  }

 componentDidMount() {
   if (this.props.giveFocusOnMount && this.props.giveFocusOnMount===true) {
      this.focusTextInput();
   }
 }

  //can be triggered by different sources
  //we want to collect the value and return it to the upper handler
  handleSubmit(evt) {
    evt.preventDefault(); // empeche évènements indésirables de <form>
    const upperOnSubmitHandler = this.props.onSubmit;
    const val = this.textInput.current.value;
    upperOnSubmitHandler(val);
  }

 render() {
   const label = this.props.label;
   const btnTxt = this.props.btnTxt;

  return (
    <div id="container_box">
    <form>{/*sa présence permet le 'enter' submit au clavier*/}
    {label} <input className="textInput" ref={this.textInput} type="text" onSubmit={this.handleSubmit} />
    <button id="btnSearch" onClick={this.handleSubmit}><img id="btnSearchImg" src={ico_search} alt={btnTxt} /></button>
    </form>
    </div>
    );
  }

}

export default SearchBar;
