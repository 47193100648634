import React from 'react';
import './BoutonOnOff.css';

/*
Objectif: bouton On/off avec une icone
lblTxt texte alt pour image du bouton
icon icone a apposer sur le bouton
activated (true/false) position initiale du bouton
<input type="checkbox" defaultChecked={isChecked} />{/*input holds css state*/

function BoutonOnOffControlled(props) {
  const onClick = props.onClick;
  const bChecked = (props.checked===true);
  const icon = props.icon;
  const lblTxt = props.lblTxt;
  //const isChecked = props.activated;
  const classes = (bChecked ? "btnOnOff rectButton checkedClass" : "boo rectButton");
  return (
    <label className="switch" >
      <span className={classes} >
        <img onClick={onClick} src={icon} alt={lblTxt} width="10mm" />
      </span>
    </label>
    );
}

export default BoutonOnOffControlled;
