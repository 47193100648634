import React, { Component } from 'react';

class Form extends Component {
   constructor(props) {
     super(props);
     this.handleSubmit = this.handleSubmit.bind(this);
  }

  //can be triggered by different sources
  //we want to collect the value and return it to the upper handler
  handleSubmit(event) {
    const upperOnSubmitHandler = this.props.onSubmit;
    event.preventDefault();
    upperOnSubmitHandler();
  }

 render() {

  return (
    <div id="container_box">
      <form onSubmit={this.handleSubmit}>
      {this.props.children}
      </form>
    </div>
    );
  }

}

export default Form;
