import React from 'react';
import './VignetteChant.css';
import ico_auteur from './img/ico_auteur_noborder.svg';
import ico_compo from './img/ico_compo_noborder.svg';


function VignetteChant(props) {

  // const jsonContent = {
  //     "_id": "45867cf2f5aae8bc98b12952a5012a8a",
  //     "_rev": "2-8b68442dd09a9e63d0a652411ff43f57",
  //     "Compositeur": "F.Lalanne",
  //     "lyrics": "",
  //     "Ancienne cote SECLI": "",
  //     "item_name": "A partager ton pain",
  //     "topics": {
  //       "T520000899": {
  //         "viewpoint": "Viewpoint5"
  //       }
  //     },
  //     "Nom principal": "A partager ton pain",
  //     "Auteur": "F.Lalanne",
  //     "item_corpus": "Jacques_Breton_chants",
  //     "thumbnail": "2px.png"
  //   };

  const jsonContent = props.jsonContent;
  //const nom = jsonContent.item_name;
  const titre = jsonContent['Nom principal'];
  const compo = jsonContent.Compositeur;
  const cote = jsonContent['Ancienne cote SECLI'];
  const auteur = jsonContent.Auteur;
//envisager aussi des liens vers partitions, vidéo...

  //for highlighting
  //this.props.searchStr

  //pour les paroles, on s'en servira peut être pour générer des aperçus flottants
  //const lyrics = jsonContent.lyrics;

  return (
   <div className="vignetteChant" >
    <div className="left-zone">
    </div>
    <div className="center-zone">
        <div className="titre">
         {titre}&nbsp;
        </div>
        <div className="dessous_centre">
            <div className="cote">
             {cote}&nbsp;
            </div>
            <div className="authComp">
                <div className="auteur">
                 <img src={ico_auteur} alt="auteur:" />{auteur}
                </div>
                <div className="compo">
                 <img src={ico_compo} alt="compositeur:" />{compo}
                </div>
            </div>
        </div>
    </div>
    <div className="right-zone">
    </div>

   </div>
   );
}

export default VignetteChant;
