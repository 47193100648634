import React, { Component } from 'react';

import './MenuBtnCritSearch.css';
import BoutonOnOffControlled from '../BoutonOnOff/BoutonOnOffControlled.js';
import BoutonMultiEtatsControlled from '../BoutonMultiEtats/BoutonMultiEtatsControlled.js';

import ico_btn_titre from './img/ico_btn_titre.svg';
import ico_btn_paroles from './img/ico_btn_paroles.svg';
import ico_btn_auteur from './img/ico_btn_auteur.svg';
import ico_btn_compositeur from './img/ico_btn_compositeur.svg';
import ico_btn_intersection from './img/ico_btn_inter.svg';
import ico_btn_union from './img/ico_btn_union.svg';

var objBoutonMultiEtats = {
    'initState':'intersection',
  'etats': [
    { 'stateName':'union',
      'icon':ico_btn_union
    },
    { 'stateName':'intersection',
      'icon':ico_btn_intersection
    }
  ]
};


class CtrlMenuBtnCritSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchArgs:{
        titre:true,
        paroles:false,
        auteur:false,
        compositeur:false,
        union:true
      }
    };

    this.upd_titre = this.upd_titre.bind(this);
    this.upd_paroles = this.upd_paroles.bind(this);
    this.upd_auteur = this.upd_auteur.bind(this);
    this.upd_compositeur = this.upd_compositeur.bind(this);
    this.upd_titre = this.upd_titre.bind(this);
    this.upd_union = this.upd_union.bind(this);
    this.functionalityPostUpdateCheck = this.functionalityPostUpdateCheck.bind(this);
    //this.buildRequest = this.buildRequest.bind(this);
  }

  upd_titre() {
    let searchArgs = this.state.searchArgs;
    searchArgs.titre = !searchArgs.titre;
    this.setState({searchArgs:searchArgs});
    this.functionalityPostUpdateCheck();
    //this.buildRequest();
  }

  upd_paroles() {
    let searchArgs = this.state.searchArgs;
    searchArgs.paroles = !searchArgs.paroles;
    this.setState({searchArgs:searchArgs});
    this.functionalityPostUpdateCheck();
    //this.buildRequest();
  }

  upd_auteur() {
    let searchArgs = this.state.searchArgs;
    searchArgs.auteur = !searchArgs.auteur;
    this.setState({searchArgs:searchArgs});
    this.functionalityPostUpdateCheck();
    //this.buildRequest();
  }

  upd_compositeur() {
    let searchArgs = this.state.searchArgs;
    searchArgs.compositeur = !searchArgs.compositeur;
    this.setState({searchArgs:searchArgs});
    this.functionalityPostUpdateCheck();
    //this.buildRequest();
  }

  upd_union() {
    let searchArgs = this.state.searchArgs;
    searchArgs.union = !searchArgs.union;
    const st_union = this.state.searchArgs.union;
    objBoutonMultiEtats.initState = ( st_union ? "union": "intersection");
    this.setState({searchArgs:searchArgs});
    this.functionalityPostUpdateCheck();
  }

  functionalityPostUpdateCheck() {
    let searchArgs = this.state.searchArgs;

    let sa_titre_changed = false;
    if (!(searchArgs.titre || searchArgs.paroles || searchArgs.auteur || searchArgs.compositeur )) {
      //say user: one search field selected needed
      searchArgs.titre = true;
      sa_titre_changed = true;
      this.setState({searchArgs:searchArgs});
    }
    //let req = gen_req("image amour", searchArgs);
    this.props.onChange(searchArgs);
    if (sa_titre_changed) {
      this.setState({searchArgs:searchArgs});
    }
  }

  render() {
    const st_titre = this.state.searchArgs.titre;
    const st_paroles= this.state.searchArgs.paroles;
    const st_auteur = this.state.searchArgs.auteur;
    const st_compositeur = this.state.searchArgs.compositeur;
    //const st_union = this.state.searchArgs.union;
    //const etatCourant = ( st_union ? "union": "intersection");


    return (
      <div>
      {/*(this.state.searchArgs.titre ? "oyo" : "aya" ) */}
        <BoutonOnOffControlled onClick={this.upd_titre} checked={st_titre} icon={ico_btn_titre} lblTxt="title" />
        <BoutonOnOffControlled onClick={this.upd_paroles} checked={st_paroles} icon={ico_btn_paroles} lblTxt="paroles" />
        <BoutonOnOffControlled onClick={this.upd_auteur} checked={st_auteur} icon={ico_btn_auteur} lblTxt="auteur" />
        <BoutonOnOffControlled onClick={this.upd_compositeur} checked={st_compositeur} icon={ico_btn_compositeur} lblTxt="compositeur" />
        <BoutonMultiEtatsControlled onClick={this.upd_union} jsonObj={objBoutonMultiEtats} />
      </div>
    );
  }

};

export default CtrlMenuBtnCritSearch;
