import React, { Component } from 'react';
import './ViewResults1.css';


class ViewResults1 extends Component {
  // constructor(props) {
  //   super(props);
  // }

 render() {
   const jsonContent = this.props.jsonContent;

  return (
    <div className="viewResults1" >
    Results:
      <pre>
      {JSON.stringify(jsonContent, null, 2)}
      </pre>
    </div>
    );
  }

}

export default ViewResults1;
