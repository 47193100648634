const fetch = require('node-fetch');
//var objectPath = require('object-path');


module.exports = function DbRequests(services) {

  const _fetch = function _fetch(id, request, success) {
    return fetch(services[0] + '/' + id, request)
    .then(function(x) {
      if (x.ok) {
        return x.json();
      } //throw x.statusText();
    });
    // .then(function(x) {
    //   return success(x);
    // });
  };

  // _find pour lancer une requete
  //o est l'objet requete
    this.query = function(o) {
      return _fetch('_find', {
        method:'POST',
        headers: {'Content-type': 'application/json' },
        credentials:'include',
        body: JSON.stringify(o)
      }
      //, function(x) {
      //  return Object.assign(o, {_id: x.id, _rev: x.rev });
      //}
    );
    };

  this.post = function(o) {
    return _fetch('', {
      method:'POST',
      headers: {'Content-type': 'application/json' },
      credentials:'include',
      body: JSON.stringify(o)
    }, function(x) {
      return Object.assign(o, {_id: x.id, _rev: x.rev });
    });
  };


//!! à vérifier
  this.put = function(o) {
    return _fetch('', {
      method:'PUT',
      headers: {'Content-type': 'application/json' },
      credentials:'include',
      body: JSON.stringify(o)
     }//, function(x) {
    //   return Object.assign(o, {_id: x.id, _rev: x.rev });
    // }
    );
  };

  this.puput = function (id, o) {
    return fetch('http://localhost:5984/partitions/'+id, {
      method:'PUT',
      headers: {'Content-type': 'application/json' },
      credentials:'include',
      body: JSON.stringify(o)
    })
    .then(res => res.json());
  };

  return this;
};
