import React, { Component } from 'react';



class TextInput extends Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.ijf = this.ijf.bind(this);
    if (!this.props.handleChange) {
      console.log("TextInput warning: no props.handleChange defined");
    }
  }

ijf(input) {
    return ( (typeof(input) === 'object') ? input : JSON.parse(input));
  }

onChangeHandler(event) {
  this.props.handleChange(event);
}

 render() {
   //const jsonFeed = JSON.parse(this.props.jsonFeed);
   const jsonFeed = this.props.jsonFeed;
   //const jsonFeed = this.ijf(this.props.jsonFeed);
   const label = jsonFeed.label;
   const name = jsonFeed.name;
   let value = jsonFeed.value;

  return (

    <div className="form_row">
    		<div className="form_left_field">{label}:</div>
    		<div className="form_right_field">
          <input type="text" name={name}  defaultValue={value} onChange={this.onChangeHandler}/>
        </div>
    	</div>
    );
  }
}

export default TextInput;
