import React, { Component } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ico_params from './img/ico_config.svg';
import conf from './config/config.json';
import SearchBar from './components/SearchBar/SearchBar.js';
import ViewJsonRequest from './components/ViewJsonRequest/ViewJsonRequest.js';
import ViewListeChants from './components/ViewListeChants/ViewListeChants.js';
import ViewResults1 from './components/ViewResults1/ViewResults1.js';
import ScreenEditChant from './components/ScreenEditChant/ScreenEditChant.js';
import CtrlMenuBtnCritSearch from './components/MenuBtnCritSearch/CtrlMenuBtnCritSearch.js';
import * as GR from './components/utils/searchBuildReq.js';
import DbRequests from './services/xhr.js';
const dbserv = new DbRequests(conf.services);

var dev_debug = false;

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResultData = this.handleResultData.bind(this);
    this.toggleShowConf = this.toggleShowConf.bind(this);
    this.toggleShowReq = this.toggleShowReq.bind(this);
    this.toggleParametersMenu = this.toggleParametersMenu.bind(this);
    this.handleCritSearchChange = this.handleCritSearchChange.bind(this);
    this.doRequest = this.doRequest.bind(this);
    this.state = {
        "searchStr":"",
        "request":"",
        "viewResultData":"none",
        "showconf":false,
        "showreq":false,
        "showparams":false,
        "critSearch":{
            titre:true,
            paroles:false,
            auteur:false,
            compositeur:false,
            union:true
        }
      };
      this.pagerRef = React.createRef();
  }

  toggleParametersMenu() {
    this.setState({"showparams": !this.state.showparams});
  }

  toggleShowConf() {
    this.setState({"showconf": !this.state.showconf});
  }

  toggleShowReq() {
    this.setState({"showreq": !this.state.showreq});
  }

  doRequest() {
    const searchStr = this.state.searchStr;
    let req = {};
    if (this.state.searchStr !== '') {
      req = GR.gen_req(searchStr, this.state.critSearch);
      this.setState({request: req});
      dbserv.query(req).then(
        queryResult => this.handleResultData(queryResult)
      );
    } //!! gestion searchStr vide? on pourrait éviter des aller retours inutiles
  }

  handleSubmit(val) {
    this.setState({searchStr: val});
    this.doRequest();
  }

  handleCritSearchChange(data) {
    this.setState({critSearch:data});
    this.doRequest();
  }

/*réceptionne ce qui arrive de la base de données
répartis les résultats dans diverses structures,
en veillant à ce que les données fournies à l'affichage
 soient de taille raisonnable.
(ne pas mettre trop gros dans setState).
Il faudrait peut être un service de stockage annexe
 et mise en cache pour limiter les aller-retours avec la base.
*/
  handleResultData(obj) {
    let data = {};// obj;//.slice(0,25);
    if (typeof obj !== 'undefined') {
    //limite les données d'affichage à la tranche que voici.
      data.docs = obj.docs;//.slice(0,5);
      data.numFound = obj.docs.length;
      this.setState({viewResultData: data});
      this.pagerRef.current.handleDoUpdate();
    }
  }


 render() {
   const showconf = this.state.showconf;
   const showreq = this.state.showreq;

   return (
    <React.Fragment>
    <div className="hLayoutRow">
    <div className="appPhoneScreen">
          <header className="App-header">
            <div className="showconfig">
              <div className="jsonViewer">
                <pre>
                {showconf && JSON.stringify(conf, null, 2)}
                </pre>
                </div>
              </div>
            <CtrlMenuBtnCritSearch onChange={this.handleCritSearchChange}/>
            {/*<MenuBtnCritSearch />*/}
            <SearchBar btnTxt="go" onSubmit={this.handleSubmit} giveFocusOnMount/>
          </header>
          <ViewListeChants passPagerRef={this.pagerRef} jsonContent={this.state.viewResultData} searchStr={this.state.searchStr}/>
    </div>{/*<!--.appPhoneScreen-->*/}

    {dev_debug &&
      <div>
      <img onClick={this.toggleParametersMenu} src={ico_params} alt="config"/>
      <button onClick={this.toggleShowConf}>conf</button>
      <button onClick={this.toggleShowReq}>req</button>
      <button onClick={this.toggleShowJsonRes}>res json</button>
      </div>
    }
    {showreq && <ViewJsonRequest jsonContent={this.state.request} />}
    {/*}<ViewResults1  jsonContent={this.state.viewResultData} />*/}
    {/*}<ScreenEditChant />*/}
    </div>{/*<!--.hLayoutRow-->*/}

    </React.Fragment>
    );
  }
}


function App() {
  return (
    <div className="App">
    <MainApp />
    </div>
  );
}

export default App;
