import React, { Component } from 'react';

class SubmitInput extends Component {

ijf(input) {
    return ( (typeof(input) === 'object') ? input : JSON.parse(input));
  }

 render() {
   const jsonFeed = this.ijf(this.props.jsonFeed);
   const name = jsonFeed.name;
   const value = jsonFeed.value;

  return (

    <div className="form_row">
    		<div className="form_left_field"></div>
    		<div className="form_right_field">
          <input type="submit" name={name} value={value}  />
        </div>
    	</div>
    );
  }
}

export default SubmitInput;
